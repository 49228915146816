.ReactVirtualized__Table__headerSticky {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
}

.ReactVirtualized__Table__headerColumn {
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn:last-child {
  right: 0;
  z-index: 2;
}

.ReactVirtualized__Table__headerColumn:first-child {
  left: 0;
  z-index: 2;
}

.ReactVirtualized__Grid {
  outline: none;
}

.TableGridOverflow {
  overflow-y: scroll !important;
  overflow-x: auto !important;
  background-color: white;
}

.ReactVirtualized__Table {
  position: relative;
}

.tableOverlayContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  color: gray;
}

.tableOverlayContent {
  margin: auto;
}