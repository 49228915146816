.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

a, a:link, a:visited {
  text-decoration: none;
}

.App-intro {
  font-size: large;
}

/* .MuiDrawer-paper-71 {
  top: 64px !important;
} */
.second > div {
  left: 235px
}
.labels {
  display: flex;
  justify-content: space-between;
  width: 100px;
  align-items: center;
  padding: 0 20px;
  margin-right: 10px;
  background: skyblue;
  border-radius: 5px;
  color: #fff,
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.MuiDrawer-paper {
  top: 64px !important;
}

a {
  color: black;
}

/* .header-root .MuiTypography-body1 {
  color: #fff !important;
}

#panel1bh-header .MuiTypography-body1 {
  color: #000000!important;
}

.MuiExpansionPanelDetails-root {
  display: block !important;
  padding: 0 !important;
}
*/
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: transparent !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.MuiExpansionPanelSummary-content {
  justify-content: space-between;
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiExpansionPanelSummary-root.Mui-disabled {
  opacity: 1 !important;
  padding-right: 0 !important;
  width: 96% !important;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.snackbar-top {
  top: 68px !important
}