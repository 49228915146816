html {
  /* scroll-snap-type: y proximity; */
  scroll-behavior: smooth;
}
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "icomoon", sans-serif;
  src:  url('fonts/icomoon.eot?t3jnjo');
  src:  url('fonts/icomoon.eot?t3jnjo#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?t3jnjo') format('truetype'),
    url('fonts/icomoon.woff?t3jnjo') format('woff'),
    url('fonts/icomoon.svg?t3jnjo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon, sans-serif' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-home:before {
  content: "\f015";
}
.icon-external-link:before {
  content: "\f08e";
}