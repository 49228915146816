
.home-wrapper {
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 45px;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 1em 0;
  margin-bottom: -130px;
}

.home-grid-wrapper {
  /* flex-grow: 1; */
  /* background: linear-gradient(90deg, rgba(0,113,233,1) 0%, rgba(0,212,255,1) 51%, rgba(0,113,233,1) 100%); */
  background: #f5f6f8;
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 35px;
  
}

.home-container {
  max-width: 1024px;
  margin: auto;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px) {
  .header-container {
    background: none;
  }
}

@media screen and (min-width: 375px) {
  .header-container {
    padding: 1em;
  }
}

.content-title {
  color: white;
  font-size:34px;
  font-weight:700;
  line-height:46px;
  /* padding-left:-50px; */
   width:500px;
   align-self: flex-start;
   padding-top: 8px;
   padding-left: 58px;
}

.content-logo-text {
  font-size: 27px;
  margin-top: -2px;
  margin-left: -7px;
  margin-right: 10px;
}

.vl {
  border-left: 1.5px solid white;
  height: 12em;
  width: 0;
  margin-right: 3em;
}

.app-title {
  color: white;
  transform: rotate(-90deg);
  font-size:30px;
  font-weight:700;
}

header {
  box-shadow: none !important;
}